<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4><i class="ti-world"> </i> 通用管理 | 申请表单</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <el-button @click="showAdd" type="success">添加申请</el-button>
          <!--          <el-button @click="edit" type="primary">编辑</el-button>-->
          <el-button @click="delMulti" type="danger">批量删除</el-button>
        </div>
        <el-dialog :visible="addShow" title="选择申请类型" :before-close="hideAdd">
          <div>
            <span>申请类型：</span>
            <el-select v-model="keys">
              <el-option v-for="item in formList" :key="item.Key" :value="item.Key" :label="item.Value"></el-option>
            </el-select>
          </div>
          <div slot="footer" align="center">
            <el-button @click="hideAdd">关闭</el-button>
            <el-button @click="getForm" type="primary">确定</el-button>
          </div>
        </el-dialog>
        <el-dialog :visible="showForm" :title="formInfo.Name" :before-close="handleClose">
          <Parser v-if="keys&&formConf" :formConf="formConf" @submit="submit"></Parser>
        </el-dialog>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="w-block" style="padding: 2px;">
            <el-select class="w-item" v-model="grid.sea.SearchKeys" placeholder="请选择范围" @change="filter">
              <el-option :label="it.value" :value="it.key" v-for="it in SearchKeyList" :key="it.key"/>
            </el-select>
            <el-select v-if="grid.sea.SearchKeys!='sp'" class="w-item" v-model="grid.sea.seA_STATE"
                       placeholder="请选择状态" @change="filter">
              <el-option label="全部" :value="0"/>
              <el-option label="已审核" :value="1"/>
              <el-option label="未审核" :value="2"/>
            </el-select>
            <el-select v-if="grid.sea.SearchKeys=='sp'" class="w-item" v-model="grid.sea.search_SEA_STATE"
                       placeholder="请选择状态" @change="filter">
              <el-option label="待审批" :value="0"/>
              <el-option label="已审批" :value="1"/>
            </el-select>
            <el-select class="w-item" v-model="grid.sea.formKey" placeholder="请选择类型" clearable @change="filter">
              <el-option :label="it.Value" :value="it.Key" v-for="it in formList" :key="it.Key"/>
            </el-select>
            <el-date-picker class="w-item" v-model="grid.sea.starT_DATE" value-format="yyyy-MM-dd" type="date" clearable
                            placeholder="请选择开始日期" @change="filter"/>
            <el-date-picker class="w-item" v-model="grid.sea.enD_DATE" value-format="yyyy-MM-dd" type="date" clearable
                            placeholder="请选择结束日期" @change="filter"/>
          </div>
          <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX+1"
                 @page-changed="getList" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="50" align="center"></el-table-column>
            <el-table-column v-for="item in grid.title" :key="item.key" :prop="item.key" :label="item.label" width="100"
                             align="center"/>
            <el-table-column label="操作" align="center">
              <template slot-scope="{row}">
                <el-button @click="getDetail(row)" type="primary" size="mini">详情</el-button>
                <!--                <el-button @click="edit(row)" type="primary" size="mini">编辑</el-button>-->
                <!--                <el-button @click="del(row)" type="primary" size="mini">删除</el-button>-->
              </template>
            </el-table-column>
          </wgrid>
        </div>
      </div>
    </div>

    <!--详情-->
    <el-dialog :visible="showDetail" title="详情" :before-close="hideDetail">
      <div v-if="detail" class="formInfo">
        <template v-for="item in detail.Titles">
          <div v-if="ignoreKeys.indexOf(item.key)==-1" class="formItem" :key="item.key">
            <div class="label">{{ item.label }}：</div>
            <div class="value">{{ detailData[item.key] }}</div>
          </div>
        </template>
        <div v-if="detail.ApproveTeacherList" class="formItem" style="align-items: center">
          <div class="label">审批人 ：</div>
         <div class="value">{{detail.ApproveTeacherList.join(',')}}</div>
        </div>
        <div v-if="detail.CanApprove==1" class="formItem" style="align-items: center">
          <div class="label">审批意见：</div>
          <el-radio-group v-if="detail.CanApprove==1" v-model="sp.status">
            <el-radio :label="1" style="margin-bottom: 0">同意</el-radio>
            <el-radio :label="2" style="margin-bottom: 0">驳回</el-radio>
          </el-radio-group>
        </div>
        <div v-if="sp.status == 2" class="formItem" >
          <div class="label">驳回原因：</div>
          <el-input type="textarea" v-model="sp.note" :rows="2"></el-input>
        </div>
      </div>
      <div class="btns" slot="footer" align="center">
        <el-button @click="hideDetail">关闭</el-button>
        <el-button @click="approve" v-if="detail.CanApprove==1" type="primary">审批</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Wgrid from "@/components/wgrid";
import Swal from "sweetalert2";
import Parser from '@/components/formGenerator/components/parser/Parser'

export default {
  name: "index",
  components: {Wgrid, Parser},
  data() {
    return {
      ignoreKeys:['id'],
      grid: {
        sea: {
          SearchKeys: 'mine',
          formKey: '',
          starT_DATE: '',
          enD_DATE: '',
          seA_STATE: 0,
          search_SEA_STATE: 0,
          ['_pageSize']: 12,
          ['_curPage']: 0,
        },
        title: [],
        ls: [],
        total: 0,
        loading: false,
      },
      ids: '',
      addShow: false,
      formList: '',
      keys: '',
      SearchKeyList: [{key: 'mine', value: '我的申请'}, {key: 'sp', value: '我的审批'}, {
        key: 'all',
        value: '所有申请'
      }],
      formConf: '',
      showForm: false,
      formInfo: '',
      showDetail: false,
      detail: '',
      detailData: '',
      sp:{
        status:'',
        note:'',
      }
    }
  },
  mounted() {
    this.getConfig()
  },
  methods: {
    getConfig() {
      let self = this;
      this.whale.remote.getResult({
        url: "/api/api/flow-form-data/config",
        completed: function ({ResultType, Message, AppendData}) {
          if (ResultType == 0) {
            let {formList} = AppendData
            self.formList = formList
            if (formList.length > 0) {
              self.grid.sea.formKey = formList[0].Key
            }
            self.getList()
          } else {
            self.whale.toast.err(Message)
          }
        }
      })
    },
    getList() {
      let self = this;
      self.grid.loading = true;
      let queryData = {}
      if (self.grid.sea.SearchKeys == 'sp') {
        let {seA_STATE, ...model} = self.grid.sea
        queryData = model
      } else {
        let {search_SEA_STATE, ...model} = self.grid.sea
        queryData = model
      }
      this.whale.remote.getResult({
        url: "/api/api/flow-form-data/page",
        data: queryData,
        completed: function (data) {
          self.grid.loading = false
          if (data.ResultType == 0) {
            self.grid.title = data.AppendData.Titles
            self.grid.ls = data.AppendData.datas.ITEMS
            self.grid.total = data.AppendData.datas.TOTAL
          } else {
            self.whale.toast.err(data.Message)
          }
        }
      })
    },
    filter() {
      this.grid.sea['_curPage'] = 0
      this.getList()
    },
    getForm() {
      this.addShow = false
      let self = this;
      this.whale.remote.getResult({
        url: "/api/api/flow-form/detail?key=" + this.keys,
        completed: function ({ResultType, Message, AppendData}) {
          if (ResultType == 0) {
            self.formInfo = AppendData
            self.formConf = JSON.parse(AppendData.EditorTxt)
            self.showForm = true
          } else {
            self.whale.toast.err(Message)
          }
        }
      })
    },
    getDetail({id}) {
      let self = this;
      this.whale.remote.getResult({
        url: "/api/api/flow-form-data/detail?id=" + id,
        completed: function ({ResultType, Message, AppendData}) {
          if (ResultType == 0) {
            self.detail = AppendData
            self.detailData = AppendData.data
            self.showDetail = true
          } else {
            self.whale.toast.err(Message)
          }
        }
      })
    },
    submit(val) {
      let datas = JSON.stringify(val)
      if (this.id) {
        this.update(datas)
      } else {
        this.add(datas)
      }
    },
    update(datas) {
      let self = this
      this.whale.remote.getResult({
        url: "/api/api/flow-form-data/update",
        data: {
          formKey: self.keys,
          datas,
          id:this.id,
        },
        completed: function ({ResultType, Message, AppendData}) {
          if (ResultType == 0) {
            self.$router.back()
          } else {
            self.whale.toast.err(Message)
          }
        }
      })
    },
    add(datas) {
      let self = this
      this.whale.remote.getResult({
        url: "/api/api/flow-form-data/add",
        data: {
          formKey: self.keys,
          datas,
        },
        completed: function ({ResultType, Message, AppendData}) {
          if (ResultType == 0) {
            self.showDetail = false
            self.getList()
          } else {
            self.whale.toast.err(Message)
          }
        }
      })
    },
    del({Id}) {
      let self = this;
      Swal.fire({
        text: "确定要删除吗？",
        icon: "question",
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        showCancelButton: true,
      }).then((v) => {
        if (v.isConfirmed) {
          self.whale.remote.getResult({
            url: "/api/api/flow-form/delete",
            data: {Id},
            completed: function () {
              self.whale.toast.info("删除成功")
              self.getList()
            }
          })
        }
      })
    },
    delMulti() {
      let self = this;
      let ids = self.ids
      if (!ids) {
        self.whale.toast.info("请选择要删除的表单")
        return
      }
      Swal.fire({
        text: "确定要批量删除吗？",
        icon: "question",
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        showCancelButton: true,
      }).then((v) => {
        if (v.isConfirmed) {
          self.whale.remote.getResult({
            url: "/api/api/flow-form/batchdelete/" + ids,
            completed: function () {
              self.whale.toast.info("删除成功")
              self.getList()
            }
          })
        }
      })
    },
    handleSelectionChange(val) {
      let ids = val.map((value) => value.Id)
      this.ids = ids.join(',')
    },
    showAdd() {
      this.keys = ''
      this.addShow = true
    },
    hideAdd() {
      this.addShow = false
    },
    handleClose() {
      this.showForm = false
    },
    hideDetail(){
      this.showDetail=false
    },
    approve(){
      let self = this
      if(self.sp.status==''){
        self.whale.toast.err('请选择审批意见')
        return
      }

      this.whale.remote.getResult({
        url: "/api/api/flow-form-data/approve",
        data: {id:self.detailData.id,...self.sp},
        completed: function ({ResultType, Message, AppendData}) {
          self.whale.toast.info('审批成功')
          self.showDetail = false
          self.getList()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.w-item {
  margin-right: 5px;
}

.formInfo {
  .formItem {
    padding: 4px 0;
    display: flex;
    .label{
      flex: 0 0 auto;
    }
  }
}

//在全局设置
input[aria-hidden=true]{
  display: none !important;
}
</style>